
import { fetchGet, fetchPost, fetchPut } from '../../router/https'
// 购物车数据
function getCartList(data) {
    return fetchGet("/mallapp/shoppingcart/page", data)
}
// 购物车删除
function delCartItem(data) {
    return fetchPost("/mallapp/shoppingcart/del", data)
}
// 购物车结算
function cartAccount(data) {
    return fetchPost("/mallapp/shoppingcart/settleaccount", data)
}
// 清空失效商品
function removeDisabledItem(data) {
    return fetchPost("/mallapp/shoppingcart/del", data)
}
// 修改购物车
function updateshoppingcart(data) {
    return fetchPut("/mallapp/shoppingcart", data)
}
export {
    getCartList,
    delCartItem,
    cartAccount,
    removeDisabledItem,
    updateshoppingcart
} 