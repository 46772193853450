<template>
  <div>
    <div>
      <!-- 导航 -->
      <div>
      <van-nav-bar
        fixed = 'true'
        placeholder = 'true'
        title="购物车"
        left-arrow
        :right-text="manageFinished"
        @click-left="onClickLeft"
        @click-right="onClickRight"
      />
      </div>
      <!-- 店铺 -->
      <div>
        <div class="cart-item" v-for="(item, index) in cartList" :key="index">
          <div class="shop-name">
            <van-checkbox
              checked-color="#fb2b53"
              v-model="item.shopInfoIsCheck"
              @change="allShopCheckedBtn(item)"
            ></van-checkbox>
            <span style="font-size: 16px; font-weight: bold">{{
              item.name
            }}</span>
          </div>
          <div class="items">
            <div
              class="item"
              v-for="(item2, index2) in item.shoppingCartList"
              :key="index2"
            >
              <div class="item-info">
                <div class="info-top">
                  <div class="top-left">
                    <van-checkbox
                      checked-color="#fb2b53"
                      @change="singleCheckedBtn(item, item2)"
                      v-model="item2.shopCarIsCheck"
                    ></van-checkbox>
                  </div>
                  <div class="top-right">
                    <img :src="item2.picUrl" />
                    <div class="shop-desc">
                      <span style="margin-right: 10px">{{
                        item2.spuName
                      }}</span>
                      <span
                        ><span>{{ item2.specInfo }}</span></span
                      >
                    </div>
                  </div>
                </div>
                <div class="info-bottom">
                  <div class="price">
                    <span class="cMoney">￥</span>
                    <span class="hMoney">{{ item2.addPrice }}</span>
                  </div>
                  <van-stepper
                    input-width="40px"
                    button-size="25px"
                    v-model="item2.quantity"
                    @change="add(item2, $event)"
                  />
                </div>
                <div
                  :class="['last-border', { active: index == item.length }]"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 失效商品 -->
      <div class="lose-items" v-if="this.disableCartList.length">
        <div class="lose-item">
          <div class="lose-top">
            <span style="font-size: 16px; font-family: PingFangSC-Medium"
              >失效商品{{ disableCartList.length }}件</span
            >
            <span style="color: #fb2b53" @click="delLoseItem"
              >清空失效商品</span
            >
          </div>
          <div
            class="item"
            v-for="(item, index) in disableCartList"
            :key="index"
          >
            <div class="item-info">
              <div class="info-top">
                <div class="top-left">
                  <span style="color: #999999">失效</span>
                </div>
                <div class="top-right">
                  <img :src="item.picUrl" />
                  <div class="shop-desc">
                    <span style="margin-right: 10px">{{ item.spuName }}</span>
                    <span
                      ><span style="color: #999999"
                        >宝贝已失效, 不能购买</span
                      ></span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 结算 -->
      <div class="submit">
        <van-submit-bar :button-text="submitDel" @submit="onSubmit" placeholder="true">
          <van-checkbox
            checked-color="#fb2b53"
            style="float-left"
            v-model="allChecked"
            @click="allItemChecked"
            >全选</van-checkbox
          >
          <span
            v-if="isManage"
            style="color: #323233; margin-right: -60px; font-size: 14px"
            >合计: {{ total.toFixed(2) }}</span
          >
        </van-submit-bar>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import {
  getCartList,
  delCartItem,
  cartAccount,
  updateshoppingcart,
  removeDisabledItem,
} from "@/api/cart2";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      // 管理状态
      isManage: true,
      manageFinished: "管理",
      submitDel: "结算",
      //   复选框
      shopChecked: false, //旗舰店
      itemChecked: false, //商品
      itemValue: 1, //步进器数量
      allChecked: false, //全选
      cartList: [], //购物车列表
      disableCartList: [], //失效商品
      total: 0,
      checkedList: [],
    };
  },
  mounted() {
    this.setRem();
    window.addEventListener("orientationchange", this.setRem);
    window.addEventListener("resize", this.setRem);
  },
  created() {
    // this.$store.commit("setSession", this.$route.query.session)
    this.getCartList();
    this.getTotalPrice();
  },
  methods: {
    ...mapMutations(["tsMall/set_data"]),
    add(item, event) {
      console.log(item, event, '葛先波')
      let params = {
        id: item.id,
        quantity: item.quantity,
      };
      updateshoppingcart(params).then((res) => {
        if (res.data.code === 0) {
          this.getCartList();
        }
      });
      this.getTotalPrice();
    },
    // 获取价格
    getTotalPrice() {
      let priceArr = [];
      this.cartList.map((item) => {
        item.shoppingCartList.map((x) => {
          if (x.shopCarIsCheck) {
            priceArr.push(x);
          }
        });
      });
      let totalAll = 0;
      for (let i = 0; i < priceArr.length; i++) {
        totalAll += priceArr[i].addPrice * priceArr[i].quantity;
      }
      this.total = totalAll;
      this.checkedList = priceArr;
    },
    // 获取购物车列表
    getCartList() {
      getCartList().then((res) => {
        if (res.data.code === 0) {
          this.disableCartList = res.data.data.disabledShoppingCart;
          this.cartList = res.data.data.resultPage;
        }
      });
    },
    // 单个选中
    singleCheckedBtn(item) {
      const checkedSingle = [];
      item.shoppingCartList.find((x) => {
        if (x.shopCarIsCheck === false) {
          checkedSingle.push(x);
        }
      });
      if (checkedSingle.length) {
        item.shopInfoIsCheck = false;
      } else {
        item.shopInfoIsCheck = true;
      }
      this.getTotalPrice();
    },
    // 店铺全选
    allShopCheckedBtn(item) {
      item.shoppingCartList.map((x) => {
        if (item.shopInfoIsCheck === false) {
          x.shopCarIsCheck = false;
        } else {
          x.shopCarIsCheck = true;
        }
      });
      const checked = this.cartList.every((item) => item.shopInfoIsCheck);
      this.allChecked = checked;
      this.getTotalPrice();
    },
    // 全选
    allItemChecked() {
      this.singleBtn = 1;
      const checked = this.cartList.every((item) => item.shopInfoIsCheck);
      if (checked) {
        this.cartList.map((item) => {
          item.shopInfoIsCheck = false;
        });
      } else {
        this.cartList.map((item) => {
          item.shopInfoIsCheck = true;
        });
      }
      this.getTotalPrice();
    },
    // rem
    setRem() {
      let screenWidth = document.documentElement.getBoundingClientRect().width;
      document.documentElement.style.fontSize = screenWidth / 10 + "px";
    },
    // 导航返回
    onClickLeft() {
      this.$router.go(-1);
    },
    //
    onClickRight() {
      this.isManage = !this.isManage;
      if (this.isManage === false) {
        this.manageFinished = "完成";
        this.submitDel = "删除";
      } else {
        this.manageFinished = "管理";
        this.submitDel = "结算";
      }
    },
    // 清空失效商品
    delLoseItem() {
      this.$dialog
        .confirm({
          message: "是否清空所有失效商品",
        })
        .then(() => {
          let ids = [];
          this.disableCartList.map((item) => {
            ids.push(item.id);
          });
          removeDisabledItem(ids).then((res) => {
            if (res.data.code === 0) {
              this.$Toast("清空成功");
              this.getCartList();
            }
          });
        })
        .catch(() => {});
    },

    // 结算或删除
    onSubmit() {
      let ids = [];
      if (this.checkedList.length) {
        for (let i = 0; i < this.checkedList.length; i++) {
          ids.push(this.checkedList[i].id);
        }
      }
      if (this.submitDel == "结算") {
        if (!ids.length) {
          Toast("请选择结算的商品");
          return;
        }
        // 结算购物车
        cartAccount(ids).then((res) => {
          if (res.data.code === 0) {
            this.getCartList();
            this.$router.push("/sureorderMore");
            this["tsMall/set_data"](JSON.parse(JSON.stringify(res.data.data)));
          }
        });
      } else {
        // 删除购物车数据
        if (!ids.length) {
          Toast("请选择删除的商品");
          return;
        }
        delCartItem(ids).then((res) => {
          if (res.data.code === 0) {
            Toast("删除成功");
            this.getCartList();
          }
        });
      }
    },
  },
};
</script>

<style lang="less">
#app{
  background: #f1f1f1;
}

.van-nav-bar__text{
  color: #323233;
}

/deep/ .van-nav-bar {
  position: fixed;
  width: 100%;
  top: 0;
}

.cart-item {
  margin: 10px;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: #fff;

  // margin-bottom: 30px;
  .shop-name {
    height: 40px;
    display: flex;
    align-items: center;

    .van-checkbox {
      padding: 15px;
    }
  }

  .items {
    .item {
      .item-info {
        // background-color: pink;
        // border-bottom: 1px solid red;
        .info-top {
          display: flex;
          margin-top: 10px;

          .top-left {
            padding: 15px;
            padding-top: 28px;
          }

          .top-right {
            display: flex;

            img {
              width: 65px;
              height: 70px;
              margin-right: 15px;
            }

            .shop-desc {
              display: flex;
              flex-direction: column;

              span:nth-child(2) {
                span {
                  display: inline-block;
                  background-color: #f1f1f1;
                  // width: 90%;
                  margin-top: 5px;
                  margin-right: 15px;
                  padding: 0 5px;
                  // display: flex;
                  // justify-content: center;
                  // align-items: center;
                }
              }
            }
          }
        }

        .info-bottom {
          display: flex;
          padding-top: 10px;
          justify-content: space-between;

          .price {
            margin-left: 48%;

            .cMoney {
              color: #fb2b53;
            }

            .hMoney {
              font-size: 15px;
              color: #fb2b53;
              margin-right: 10px;
            }
          }

          .van-stepper {
            margin-right: 10px;
          }
        }

        .last-border {
          width: 94%;
          height: 1px;
          margin-left: 3%;
          margin-top: 15px;
          background-color: #d8d8d8;
        }

        .active {
          background-color: #fff;
          // padding-bottom: 10px;
        }
      }
    }
  }
}

.lose-items {
  margin-bottom: 55px;

  .lose-item {
    // background-color: pink;
    padding-bottom: 10px;
    margin: 10px;

    .lose-top {
      display: flex;
      justify-content: space-between;
      padding: 10px;
    }

    .item {
      .item-info {
        // background-color: pink;
        // border-bottom: 1px solid red;
        .info-top {
          display: flex;
          margin-top: 10px;

          .top-left {
            padding: 15px;
            padding-right: 5px;
            padding-top: 28px;

            span {
              display: inline-block;
              width: 35px;
            }
          }

          .top-right {
            display: flex;

            img {
              width: 65px;
              height: 70px;
              margin-right: 15px;
            }

            .shop-desc {
              display: flex;
              flex-direction: column;

              span:nth-child(2) {
                display: inline-block;
                background-color: #f1f1f1;
                width: 150px;
                margin-top: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }

        .info-bottom {
          display: flex;
          padding-top: 10px;
          justify-content: space-between;

          .price {
            margin-left: 220px;

            .cMoney {
              color: #fb2b53;
            }

            .hMoney {
              font-size: 15px;
              color: #fb2b53;
              margin-right: 10px;
            }
          }

          .van-stepper {
            margin-right: 10px;
          }
        }

        .last-border {
          width: 94%;
          height: 1px;
          margin-left: 3%;
          margin-top: 15px;
          background-color: #d8d8d8;
        }

        .active {
          background-color: #fff;
          // padding-bottom: 10px;
        }
      }
    }
  }
}

.submit {
  height: 60px;
  .van-submit-bar__bar {
    justify-content: space-between;
    height: 60px;
  }
}

/deep/ .van-nav-bar__text {
  color: #333;
}

/deep/ .van-checkbox__icon--checked .van-icon {
  // color: #fb2b53;
  background-color: #fb2b53;
  border-color: #fb2b53;
}
</style>